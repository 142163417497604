import { useMemo } from "react";

import { loadApolloErrorMessages } from "./loadApolloErrorMessages";

/**
 * Loads Apollo error messages in development and staging so that they're printed to console.
 * In production instead of printing the error directly, it will link to a website where more information about the error can be found
 * This was done in @apollo/client 3.8.0 to reduce bundle size
 *
 * Invoke this directly within your ApolloClientProvider component before initializing the client
 */
const useApolloErrorMessages = () => {
  // useMemo is used here instead of useEffect as it will invoke the internal functions immediately
  // useEffect on the other hand will wait until after the first render/mount
  // it's done this way to display proper errors if there's anything that occurs during the first render
  useMemo(() => {
    loadApolloErrorMessages();
  }, []);
};

export { useApolloErrorMessages };
