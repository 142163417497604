const cookieExtractor = (cookiesToExtract: string[]): Record<string, string> => {
  const cookies = document.cookie.split("; ");

  return cookies.reduce((matchingCookies, cookie) => {
    const [cookieName, cookieValue] = cookie.split("=");

    if (cookiesToExtract.includes(cookieName)) {
      matchingCookies[cookieName] = cookieValue;
    }

    return matchingCookies;
  }, {});
};

export { cookieExtractor };
