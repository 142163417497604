import { loadDevMessages, loadErrorMessages } from "@apollo/client/dev";

import { isProduction } from "@shared/utils/fullscriptEnv/fullscriptEnv";

/**
 * Loads Apollo error messages in development and staging so that they're printed to console.
 * In production instead of printing the error directly, it will link to a website where more information about the error can be found
 * This was done in @apollo/client 3.8.0 to reduce bundle size
 */
const loadApolloErrorMessages = () => {
  if (!isProduction()) {
    loadErrorMessages();
    loadDevMessages();
  }
};

export { loadApolloErrorMessages };
