import type { createUploadLink as UploadLinkType } from "apollo-upload-client";
import { createUploadLink } from "apollo-upload-client";

import { graphqlFetch } from "@helpers/graphqlFetch";

const getUploadLink = (uploadLinkOptions?: UploadLinkType.UploadLinkOptions) =>
  createUploadLink({
    fetch: graphqlFetch,
    uri: "/api/graphql/v2",
    credentials: "same-origin",
    includeExtensions: true,
    ...uploadLinkOptions,
  });

export { getUploadLink };
