import type { ServerError, ServerParseError } from "@apollo/client";
import type { Event } from "@sentry/types";

import type { ExecutionResultWithAuthData } from "@shared/types/executionResultWithAuthData";

let enableErrors = true;
const turnOffErrorReportingIfLoggedOut = (
  networkError: Error | ServerError | ServerParseError,
  response?: ExecutionResultWithAuthData
) => {
  const isUserLoggedOut = response?.authentication && !response?.authentication?.role;

  if (networkError || isUserLoggedOut) {
    enableErrors = false;
  }
};

const turnOffErrorReporting = () => {
  enableErrors = false;
};

const errorsTurnedOffFilter = (event: Event): Event | null => {
  if (enableErrors) return event;

  return null;
};

export { errorsTurnedOffFilter, turnOffErrorReporting, turnOffErrorReportingIfLoggedOut };
