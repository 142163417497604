export const graphqlFetch = (uri, options) => {
  try {
    let operationName = "";
    if (typeof options.body === "string") {
      operationName = JSON.parse(options.body).operationName;
    } else if (options.body instanceof FormData) {
      operationName = JSON.parse(options.body.get("operations"))?.operationName;
    }

    return fetch(uri + "/GraphQL_" + operationName, options);
  } catch (e) {
    return fetch(uri, options);
  }
};
