import { cookieExtractor } from "@shared/helpers/cookieExtractor";
import { useSearchParams } from "@shared/react-router-dom";
import { isSSR } from "@shared/utils/fullscriptEnv/fullscriptEnv";

const COOKIE_KEY = "latest_utms";
const COOKIE_EXP_OFFSET = 30 * 24 * 60 * 60; // 30 days in seconds
const UTM_PARAMETERS = [
  "utm_source",
  "utm_id",
  "utm_term",
  "utm_content",
  "utm_channel",
  "utm_adgroup",
  "utm_matchtype",
  "utm_device",
  "utm_campaign",
  "utm_medium",
];
const convertToCamelCase = (str: string) =>
  str.replace(/_([a-z])/g, (_, letter) => letter.toUpperCase());

const getReferrerSearchParams = () => {
  if (document.referrer) {
    const referrerUrl = new URL(document.referrer);

    return new URLSearchParams(referrerUrl.search);
  }

  return new URLSearchParams();
};

const getUTMsFromCookie = (): Record<string, string> => {
  const cookieValue = cookieExtractor([COOKIE_KEY])?.[COOKIE_KEY];
  try {
    return JSON.parse(atob(decodeURIComponent(cookieValue)));
  } catch {
    return {};
  }
};

const storeUTMsInCookie = (utms: Record<string, string>) => {
  const expirationDate = new Date(Date.now() + COOKIE_EXP_OFFSET * 1000).toUTCString();
  const encodedUTMs = btoa(JSON.stringify(utms));
  document.cookie = `${COOKIE_KEY}=${encodedUTMs}; expires=${expirationDate}; path=/;`;
};

const getUTMs = (urlParams?: URLSearchParams) => {
  if (isSSR()) return {};

  const searchParams = urlParams || new URLSearchParams(window.location.search);
  const referrerSearchParams = getReferrerSearchParams();
  const cookieUTMs = getUTMsFromCookie();

  const utmParams = UTM_PARAMETERS.reduce(
    (acc, param) => {
      const value = searchParams.get(param) || referrerSearchParams.get(param);

      if (value) acc[param] = value;

      return acc;
    },
    {} as Record<string, string>
  );
  const hasUTMValues = Object.values(utmParams).some(value => !!value);

  if (hasUTMValues) storeUTMsInCookie(utmParams);

  return hasUTMValues ? utmParams : cookieUTMs;
};

const useUnauthUrlFields = ({ isCamelCaseKeys } = { isCamelCaseKeys: false }) => {
  const [searchParams] = useSearchParams();
  const payload = getUTMs(searchParams);
  if (isCamelCaseKeys) {
    return Object.fromEntries(
      Object.entries(payload).map(([key, value]) => [convertToCamelCase(key), value])
    );
  }

  return payload;
};

export { useUnauthUrlFields, getUTMs };
