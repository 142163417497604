import { createPersistedQueryLink } from "@apollo/client/link/persisted-queries";
import cryptoSha256 from "crypto-js/sha256";

type CryptoParams = [string | CryptoJS.lib.WordArray, object];

const getPersistedQueriesLink = () => {
  const sha256 = (...args: CryptoParams) => cryptoSha256(...args).toString();

  return createPersistedQueryLink({ sha256 });
};

export { getPersistedQueriesLink };
